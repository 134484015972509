<template>
    <div id="top-nav-component">
        <div class="nav-left">
            &nbsp;
        </div>
        <div class="nav-box">
            <div class="home-name">小莫陋室</div>
            <div class="nav-body">
                <div class="nav-item"
                     v-for="(topNavItem, topNavIndex) in topNavList" :key="topNavItem.code"
                     :class="topNavItem.code == active ? 'topNavActive' : ''"
                     @click="topSwitchCate(topNavIndex)"
                >{{ topNavItem.name }}
                </div>
            </div>
        </div>
        <div class="nav-right">
            &nbsp;
        </div>
    </div>
</template>

<script>
    export default {
        name: "top-nav-component",
        data() {
            return {
                topNavList: [
                    {code: 'home', name: '首页', url: '/home'},
                    {code: 'category', name: '分类', url: '/category'},
                    {code: 'navigation', name: '导航资源', url: '/navigation'}
                ]
            }
        },
        props: {
            active: {
                type: String,
                default() {
                    return '';
                },
            },
        },
        methods: {
            topSwitchCate(index) {
                let newUrl = this.topNavList[index].url;
                this.$emit('navChange', newUrl);
            }
        },
    }
</script>

<style scoped>
    #top-nav-component {
        background-color: rgba(255, 255, 255, 0.97);
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #top-nav-component .nav-left{
        max-width: 200px;
    }

    #top-nav-component .nav-right{
        max-width: 200px;
    }

    #top-nav-component .nav-box {
        /*padding: 0 40px;*/
        width: 1170px;
        height: 60px;
        background-color: rgba(255, 255, 255, 0.97);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #top-nav-component .home-name {
        letter-spacing: 3px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 600;
        color: #409EFF;
    }

    #top-nav-component .nav-body {
        display: flex;
        text-align: right;
        height: 60px;
    }

    #top-nav-component .nav-item {
        height: 60px;
        margin: 0 20px;
        font-size: 15px;
        color: #666;
        min-width: 60px;
        text-align: center;
        line-height: 60px;
    }

    #top-nav-component .nav-item:hover {
        color: #409EFF;
    }

    #top-nav-component .nav-item.topNavActive {
        color: #409EFF;
    }
</style>