<template>
    <div id="default-link-component" class="showShadow">
        <div class="link-box" v-for="linkItem in list">
            <div class="link-title">{{linkItem.name}}</div>
            <div class="link-body" v-if="linkItem.list.total > 0">
                <div class="link-item" v-for="linkInfo in linkItem.list.list"
                     @click="goLink(linkInfo.url)">
                    <el-image style="width: 50px;" :src="linkInfo.imgData" :fit="'fill'"></el-image>
                    <div class="link-name">{{linkInfo.name}}</div>
                </div>
            </div>
            <div class="link-null" v-else>
                暂无数据
            </div>
            <div class="link-other" @click="goMore(linkItem.code)">
                更多
            </div>

        </div>
    </div>
</template>

<script>
    export default {    // 主内容-默认资源链接
        name: "default-link-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        methods: {
            goMore(code) {
                this.$emit("linktypechange", code);
            },
            goLink(url) {
                window.open(url);
            }
        },       //定义组件的方法
    }
</script>

<style scoped>
    #default-link-component {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        /*align-content: flex-start;*/
        /*justify-content: space-between;*/
    }

    #default-link-component .link-box {
        display: flex;
        flex-direction: column;
        width: 360px;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        margin-right: 45px;
    }

    #default-link-component .link-box:nth-child(3n) {
        margin-right: 0;
    }

    #default-link-component .link-box .link-title {
        font-weight: 500;
        border-bottom: 1px dotted #E9E9E9;
        color: #999;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
        text-align: center;
        letter-spacing: 10px;
    }

    #default-link-component .link-box .link-body {
        height: 243px;
    }

    #default-link-component .link-box .link-body .link-item {
        margin: 15px 15px 0 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px dotted #E9E9E9;
        color: #333;
    }

    #default-link-component .link-box .link-body .link-item:hover {
        color: #409EFF;
        background: -webkit-linear-gradient(left, #FFF, #F0F2F5, #FFF);
    }

    #default-link-component .link-box .link-body .link-item:nth-child(3) {
        border-bottom: 0;
    }

    #default-link-component .link-box .link-body .link-item .link-name {
        margin-left: 15px;

        font-size: 14px;
    }

    #default-link-component .link-box .link-null {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        line-height: 243px;
        height: 150px;
    }

    #default-link-component .link-box .link-other {
        font-weight: 500;
        border-top: 1px dotted #E9E9E9;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        text-align: center;
        color: #409EFF;
    }

    #default-link-component .link-box .link-other:hover {
        background: #409EFF;
        color: #fff;
        border-radius: 6px;
    }


</style>