<template>
    <div id="hot-link-component">
        <div class="title">友情链接</div>
        <div class="link-box" v-if="total > 0">
            <div class="link-item" v-for="linkItem in list" :key="'linkIndex' + linkItem.code"
                 @click="openLink(linkItem.url)">
                {{linkItem.name}}
            </div>
        </div>
        <div class="null-tag" v-if="total <= 0">
            暂无数据
        </div>
        <div class="other-box">
            <div class="apply" @click="goApply">申请友链</div>
            <div class="more"  @click="goToMore">查看更多</div>
        </div>
        <el-dialog
                title="申请友链"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose">
            <div class="applyBox">
                <div class="siteInfo">
                    <p>名称：小莫陋室</p>
                    <p>地址：http://blog.liaoyansi.cn</p>
                    <p>介绍：小莫的技术自留地。</p>
                    <p>邮箱：leoyancy@163.com</p>
                    <p>需要换友链的朋友，先挂上本站友链，然后发送【网站名称+网站地址+网站描述+邮箱地址】到邮箱，审核通过后会挂载到本站。</p>
                </div>
                <div class="tips">
                    <p><b>温馨提示：</b></p>
                    <p>在申请本站友链之前请确保已将本站加入友链。</p>
                    <p>本站优先录入同类原创、技术类博客。任何包含违法国家法律或不健康内容站点均不录入。</p>
                    <p>本站会不定期互相走访友链，发现单方取消友链便拉入黑名单。</p>
                    <p>特别提醒: 若无意外，在申请友链后24小时内完成审核并录入站点，如超时还未审核完成，请Email或者微信我。审核结果均以邮件形式通知，请输入常用有效邮箱。</p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "hot-link-component",
        data() {
            //定义组件的数据
            return {
                dialogVisible: false
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            }
        },
        methods: {
            // 打开链接
            openLink(url) {
                window.open(url);
            },
            goApply(){
                console.log('申请友链')
                this.dialogVisible = true;
            },
            handleClose(){
                this.dialogVisible = false;
            },
            goToMore(){
                console.log('查看更多')
                this.$router.push("/friend");
            },
        },       //定义组件的方法
    }
</script>

<style scoped>
    #hot-link-component {
        display: flex;
        flex-direction: column;
        width: 350px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #hot-link-component .title {
        padding-left: 15px;
        font-weight: 500;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    #hot-link-component .link-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 15px 10px 15px;
    }

    #hot-link-component .link-box .link-item {
        width: 150px;
        font-size: 12px;
        padding-bottom: 12px;
        color: #5E6D81;
        margin-right: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    #hot-link-component .link-box .link-item:hover {
        color: #409EFF;
    }

    #hot-link-component .link-box .link-item:nth-child(2n){
        margin-right: 0;
    }

    #hot-link-component .other-box{
        border-top: 1px dotted #E9E9E9;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #hot-link-component .other-box .apply {
        width: 274px;
        color: #409EFF;
        font-size: 14px;
        line-height: 34px;
        text-align: center;
        border-right: 1px dotted #E9E9E9;
    }

    #hot-link-component .other-box .apply:hover {
        background: #409EFF;
        color: #fff;
    }

    #hot-link-component .other-box .more {
        width: 274px;
        color: #409EFF;
        font-size: 14px;
        line-height: 34px;
        text-align: center;
    }

    #hot-link-component .other-box .more:hover {
        background: #409EFF;
        color: #fff;
    }

    #hot-link-component .null-tag {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        line-height: 50px;
    }
    .applyBox{
        margin: 0;
    }
    .applyBox .siteInfo{
        padding: 10px;
        background: #f9f9f9;
        color: #999;
        font-size: 14px;
    }

    .applyBox .tips{
        color: #96969c;
        font-size: 14px;
    }
</style>