<template>
    <div class="navigation-app" v-loading="loading">
        <div v-if="showPage" class="page-main-head">
            <div class="linkTypeBox">
                <div class="linkTypeList">
                    <div class="linkTypeItem" v-for="linkTypeItem in linkType.list"
                         :key="'linkTypeIndex' + linkTypeItem.code"
                         :class="linkTypeItem.code == linkTypeCode ? 'linkTypeActive' : ''"
                         @click="linkTypeChange(linkTypeItem.code)">{{linkTypeItem.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="showPage" class="page-main-body">
            <default-link v-if="linkTypeCode == 'all'"
                          v-bind:list="defaultLink" v-on:linktypechange="linkTypeChange"></default-link>
            <link-list v-if="linkTypeCode != 'all'"
                       v-bind:list="linkList.list" v-bind:total="linkList.total"></link-list>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData} from "api/base";

    import HotArticle from "components/aside/HotArticle";
    import DefaultLink from "components/main/DefaultLink";
    import LinkList from "components/main/LinkList";

    export default {
        name: "navigation-app",
        data() {
            return {
                topNavActive: 'navigation',
                showPage: false,
                loading: false,
                defaultLink: {},
                linkList: {total:0, list: []},
                linkTypeCode: '', // 当前链接编号
                linkType:{total:0, list: []},
                total: 0,
                list: []
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                let code = this.$route.query.code;
                if (!code) {
                    code = 'all';
                }
                this.page = 1;
                this.linkTypeCode = code;
                this.getData();
            },
            getData() {
                let that = this;
                let params = {
                    code: that.linkTypeCode
                };
                let data = {type: 'LINK', params};
                initData(data)
                    .then((res) => {
                        that.linkType = res.linkType;  // 资源链接类型
                        if(that.linkTypeCode == 'all'){
                            that.defaultLink = res.defaultLink;  // 默认资源链接
                        }else{
                            that.linkList = res.defaultLink;
                        }
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面

                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            oneData() {
                let that = this;
                let params = {
                    code: that.linkTypeCode,
                };
                let data = {type: 'defaultLink', params};
                oneData(data)
                    .then((res) => {
                        if(that.linkTypeCode == 'all'){
                            that.defaultLink = res;  // 默认资源链接
                        }else{
                            that.linkList.total = res.total;  // 资源链接列表
                            that.linkList.list = res.list;  // 资源链接列表
                        }
                    })
                    .catch((err) => {
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            linkTypeChange(res) {
                let that = this;
                let newUrl = '/navigation?code=' + res;
                history.pushState({}, null, newUrl);
                that.linkTypeCode = res;
                that.oneData();
                return false;
            },
        },
        components: {
            HotArticle,
            DefaultLink,
            LinkList
        },
    };
</script>
<style scoped>
    .linkTypeBox{
        width: 100%;
        height: 50px;
    }
    .linkTypeBox .linkTypeList{
        width: 1170px;
        margin: 20px auto 0;
        height: 50px;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 4px 30px rgba(0,0,0,.08);
        -moz-box-shadow: 0 4px 30px rgba(0,0,0,.08);
        -webkit-box-shadow: 0 4px 30px rgba(0,0,0,.08);
    }
    .linkTypeBox .linkTypeItem{
        padding: 0;
        margin: 0 30px;
        height: 50px;
        line-height: 50px;
        color: #454545;
        font-size: 16px;
    }
    .linkTypeBox .linkTypeItem:hover{
        /*font-weight: 600;*/
        color: #409EFF;
    }
    .linkTypeBox .linkTypeItem.linkTypeActive{
        /*font-weight: 600;*/
        color: #409EFF;
    }
</style>
