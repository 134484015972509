<template>
    <div class="artcile-app" v-loading="loading">
        <div v-show="showPage" class="page-main-body">
            <div class="page-main-body-card article-detail-box">
                <div class="article-head">
                    <div class="article-title">{{articleInfo.title}}</div>
                    <div class="article-other">
                        <div class="category">分类：<span class="value" @click="goCategory(articleInfo.categoryCode)">{{articleInfo.categoryName}}</span></div>
                        <div class="time">时间：<span class="value">{{articleInfo.dateTime}}</span></div>
                        <div class="read">浏览：<span class="value">{{articleInfo.click}}</span></div>
                        <!--<div class="approve">赞：<span class="value">{{articleInfo.thumbsUp}}</span></div>-->
                    </div>
                </div>
                <active-box></active-box>
                <div id="editorBox">
                  <mavon-editor class="md" :value="articleContent" :subfield="false" :defaultOpen="'preview'"
                                :toolbarsFlag = "false" :editable="false" :scrollStyle="true" :ishljs="true"
                                :boxShadow="false" previewBackground="#ffffff">
                  </mavon-editor>
                </div>
            </div>
            <div class="page-main-right-card">
                <about v-bind:data="articleStatistics"></about>
                <active></active>
                <new-article v-bind:list="newArticle.list" v-bind:total="newArticle.total"></new-article>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData, clickArticle} from "api/base";

    import NewArticle from "components/aside/NewArticle";
    import HotArticle from "components/aside/HotArticle";
    import About from "components/aside/About";
    import Active from "components/aside/Active";
    import ActiveBox from "components/main/ActiveBox";

    export default {
        name: "artcile-app",
        data() {
            return {
                topNavActive: '',
                showPage: false,
                loading: false,
                code: '',
                // 文章信息
                articleInfo: {},
                // 最新文章
                newArticle: {total: 0, list: []},
                // 热门数据
                hotArticle: {total: 0, list: []},
                // 文章统计
                articleStatistics: {articleTotal: 0, articleClick: 0},
                articleContent: "",
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                let code = this.$route.query.code;
                if (!code) {
                    // 页面不存在
                    this.$message({showClose: true, message: err, type: 'error'});
                }
                this.code = code;
                this.getData();
                this.clickArticle();
            },
            getData() {
                let that = this;
                let params = {
                    articleCode: that.code
                };
                let data = {type: 'ARTICLE', params};
                initData(data)
                    .then((res) => {
                        that.articleInfo = res.articleInfo;
                        that.articleStatistics = res.articleStatistics;  // 文章统计
                        that.newArticle  = res.newArticle;
                        that.hotArticle  = res.hotArticle;
                        let markdown = decodeURIComponent(res.articleInfo.content);
                        this.showContent(markdown);
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            showContent(markdown) {
                this.articleContent = markdown;
            },
            goCategory(code,) {
                this.$router.push({ path: "/category", query: { code } });
            },
            clickArticle() {
                let that = this;
                let params = {
                    articleCode: that.code
                };
                clickArticle(params)
                    .then((res) => {
                    })
                    .catch((err) => {
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
        },
        components: {
            About,
            NewArticle,
            HotArticle,
            Active,
            ActiveBox
        },
    };
</script>
<style scoped>
html, body, #article-app {
  height: 100%;
  width: 100%;
  margin: 0;
}
.markdown-body {
  z-index: 0;
}
.article-detail-box{
  background: #fff;
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);*/
  -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  box-shadow: rgba(0, 0, 0, 0.04) 0 2px 12px 0;
}
.article-head{
  display: flex;
  flex-direction: column;
  /*margin-bottom: 15px;*/
  width: 770px;
  padding: 30px 20px 15px 20px;
  border-bottom: 1px dashed #f0f0f0;
}
.article-head .article-title{
  font-size:22px;
  line-height: 35px;
  font-weight: bold;
  padding-bottom:15px;
}

.article-head .article-other{
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #b4b4b4;
}

.article-head .article-other .category{padding-right: 20px;}
.article-head .article-other .time{padding-right: 20px;}
.article-head .article-other .read{padding-right: 20px;}

.article-head .article-other .category .value:hover{color: #00a2ff;}

#editorBox{
  width: 100%;
}
</style>
