<template>
    <div id="hot-article-component" class="showShadow">
        <div class="title">热门排行</div>
        <div class="article-box" v-if="total > 0">
            <div class="article-item" v-for="(hotArticle,articleIndex) in list"
                 :key="'hotArticleIndex' + hotArticle.code"
                 @click="goArticle(hotArticle.code)"
            >
                <div class="index">{{articleIndex + 1}}</div>
                <div class="article-info">
                    <div class="article-title">{{hotArticle.title}}</div>
                </div>
            </div>
        </div>
        <div class="null-article" v-else>
            暂无数据
        </div>
    </div>
</template>

<script>
    export default {    // 侧边-热门排行
        name: "hot-article-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            },
        },
        methods: {
            goArticle(code) {
                let url = '/article?code=' + code;
                window.open(url);
            }
        },       //定义组件的方法
    }
</script>

<style scoped>
    #hot-article-component {
        display: flex;
        flex-direction: column;
        width: 350px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #hot-article-component .title {
        padding-left: 15px;
        font-weight: 500;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    #hot-article-component .article-box {
        display: flex;
        flex-direction: column;
        padding: 13px 15px;
    }

    #hot-article-component .article-item {
        display: flex;
        flex-direction: row;
    }

    #hot-article-component .article-item:hover {
        background: -webkit-linear-gradient(left, #FFF, #F4F4F4, #FFF);
    }

    #hot-article-component .article-item .index {
        font-size: 14px;
        min-width: 10px;
        font-weight: 700;
        margin-right: 8px;
        color: rgba(255, 161, 22, 0.7);
        line-height: 40px;
    }

    #hot-article-component .article-item .article-info {
        display: flex;
        flex-direction: column;
    }

    /*标题*/
    #hot-article-component .article-item .article-title {
        font-weight: normal;
        font-size: 14px;
        width: 280px;
        color: #5E6D81;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 40px;
    }

    #hot-article-component .article-item .article-title:hover {
        color: #409EFF;
    }

    #hot-article-component .null-article {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        line-height: 50px;
    }

</style>