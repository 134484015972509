<template>
    <div id="foot">
        <div class="foot-body">
            <div class="foot-item">
                站点声明：本站转载作品版权归原作者及来源网站所有，原创内容作品版权归作者所有，任何内容转载、商业用途等均须联系原作者并注明来源。
            </div>
            <div class="foot-item">
                Copyright © {{date}} liaoyansi.cn All Rights Reserved V.1.0.0 备案号: <span
                    @click="goTo">皖ICP备16010374号-3</span>
            </div>
            <div class="foot-item">
                相关侵权、举报、投诉及建议等，请发E-mail：leoyancy@163.com
            </div>
        </div>
    </div>
</template>

<script>
    export default {    // 主内容-页脚
        name: "foot",
        data() {
            //定义组件的数据
            return {
                date: ''
            }
        },
        created() {
            this.getDate();
        },
        methods: {
            getDate() {
                let date = new Date();
                this.date = date.getFullYear();
            },
            goTo() {
                let url = 'https://beian.miit.gov.cn/';
                window.open(url);
            }
        },//定义组件的方法
        watch: {},
    }
</script>

<style scoped>

    #foot {
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;
        height: 100px;
        background: #30373C;
        border-top: 1px solid #efefef;
    }

    #foot .foot-body {
        margin: 0 auto;
        padding: 20px 0;
    }

    #foot .foot-item {
        color: #ccc;
        font-size: 12px;
        line-height: 20px;
    }

</style>