import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from 'views/Home.vue'
import Category from 'views/Category.vue'
import TagArticle from 'views/TagArticle.vue'
import Tag from 'views/Tag.vue'
import Navigation from 'views/Navigation.vue'
import SearchPage from 'views/SearchPage.vue'
import Article from 'views/Article.vue'
import Friend from 'views/Friend.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/category',
    component: Category
  },
  {
    path: '/tagArticle',
    component: TagArticle
  },
  {
    path: '/tag',
    component: Tag
  },
  {
    path: '/navigation',
    component: Navigation
  },
  {
    path: '/search',
    component: SearchPage
  },
  {
    path: '/article',
    component: Article
  },
  {
    path: '/friend',
    component: Friend
  },
  // {
  //   path: '/user',
  //   component: User,
  //   children: [{
  //       path: 'appointMentList',
  //       component: AppointMentList
  //     },
  //     {
  //       path: 'coupons',
  //       component: Coupons
  //     }
  //   ]
  // },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
