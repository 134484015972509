import { render, staticRenderFns } from "./HotFriend.vue?vue&type=template&id=5efb9252&scoped=true&"
import script from "./HotFriend.vue?vue&type=script&lang=js&"
export * from "./HotFriend.vue?vue&type=script&lang=js&"
import style0 from "./HotFriend.vue?vue&type=style&index=0&id=5efb9252&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efb9252",
  null
  
)

export default component.exports