import Vue from 'vue'
import {
    Avatar,
    Skeleton,
    Pagination,
    Input,
    Image,
    Button,
    Icon,
    Popover,
    Dialog,
    Loading
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Avatar);
Vue.use(Skeleton);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Image);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popover);
Vue.use(Dialog);
Vue.use(Loading);
