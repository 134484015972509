<template>
    <div id="search-article-list-component">
        <div class="search-article-head">
            已找到<span class="high"> {{total}} </span>条<span class="high"> {{keyword}} </span>相关结果
        </div>
        <div class="search-article-list" v-if="total > 0">
            <article-list v-bind:list="list"></article-list>
        </div>
    </div>
</template>

<script>
    import ArticleList from "components/main/ArticleList";
    export default {    // 主内容 - 搜索列表
        name: "search-article-list-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            },
            keyword: {
                type: String,
                default() {
                    return '';
                },
            },
        },
        methods: {},
        watch: {},
        components: {
            ArticleList
        },
    }
</script>

<style scoped>

    #search-article-list-component {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #search-article-list-component .search-article-head {
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        font-size: 16px;
        border-bottom: 1px dotted #E9E9E9;
        color: #999;
    }

    #search-article-list-component .search-article-head .high {
        color: #409EFF;
    }

    #search-article-list-component .search-article-pagination {
        height: 50px;
        background: #fff;
        text-align: center;
    }


</style>