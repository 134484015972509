<template>
    <div id="top-article-list-component">
        <div class="top-article-head">
            置顶
        </div>
        <div class="top-article-list">
            <article-list v-bind:list="list"></article-list>
        </div>
    </div>
</template>

<script>
    import ArticleList from "components/main/ArticleList";

    export default {    //  主内容 - 置顶文章
        name: "top-article-list-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        methods: {
            pageChange(page) {
                this.currentPage = page;
                this.$emit("topPageChange", page);
            }
        },
        watch: {},
        components: {
            ArticleList
        },
    }
</script>

<style scoped>

    #top-article-list-component {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        margin-bottom: 20px;
    }

    #top-article-list-component .top-article-head {
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
    }
</style>