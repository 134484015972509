<template>
    <div id="app" v-cloak>
        <div class="page-box">
            <div class="page-head-box">
                <top-nav :active="active" v-on:navChange="navChange"></top-nav>
            </div>
            <div class="page-main-box">
                <router-view/>
            </div>
            <div class="page-foot-box">
                <foot></foot>
            </div>
        </div>
    </div>
</template>
<script>
    import TopNav from "components/public/TopNav";
    import Foot from "components/public/Foot";

    export default {
        data() {
            return {
                currentUrl: '',
                active: '',
            };
        },
        created(){
            let path = this.$route.path;
            this.currentUrl = path;
            this.setNavActive(path);
        },
        methods: {
            setNavActive(path){
                this.currentUrl = path;
                if(path == '/home'){
                    this.active = 'home';
                    return false;
                }
                if(path == '/category'){
                    this.active = 'category';
                    return false;
                }
                if(path == '/navigation'){
                    this.active = 'navigation';
                    return false;
                }
                this.active = 'other';
            },
            navChange(url){
                if(this.currentUrl == url){
                    return false;
                }
                this.setNavActive(url);
                this.$router.push(url);
            },
        },
        watch: {
            $route(to,from){
                this.setNavActive(to.path);
            }
        },
        components: {
            TopNav,
            Foot
        }
    };
</script>

<style scoped>
    @import url("assets/css/base.css");

</style>
