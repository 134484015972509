<template>
    <div class="home" v-loading="loading">
        <div v-if="showPage" class="page-main-head"></div>
        <div v-if="showPage" class="page-main-body">
            <div class="page-main-body-card">
                <top-article-list v-bind:list="topArticleList.list" v-bind:total="topArticleList.total"
                                  v-bind:page="1"></top-article-list>
                <new-article-list v-bind:list="newArticleList.list" v-bind:total="newArticleList.total" v-bind:page="1"
                                  ></new-article-list>
            </div>
            <div class="page-main-right-card">
                <about v-bind:data="articleStatistics"></about>
                <search></search>
                <active></active>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
                <hot-tag v-bind:list="hotTag.list" v-bind:total="hotTag.total"></hot-tag>
                <hot-friend v-bind:list="hotFriend.list" v-bind:total="hotFriend.total"></hot-friend>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData} from "api/base";

    import TopArticleList from "components/main/TopArticleList";
    import NewArticleList from "components/main/NewArticleList";
    import HotArticle from "components/aside/HotArticle";
    import HotTag from "components/aside/HotTag";
    import HotFriend from "components/aside/HotFriend";
    import About from "components/aside/About";
    import Search from "components/aside/Search";
    import Active from "components/aside/Active";

    export default {
        name: "Home",
        data() {
            return {
                showPage: false,
                loading: false,
                topArticleList: {total: 0, list: []},
                newArticleList: {total: 0, list: []},
                recommendArticle: {total: 0, list: []},
                hotArticle: {total: 0, list: []},
                hotTag: {total: 0, list: []},
                hotFriend: {total: 0, list: []},
                articleStatistics: {articleTotal: 0, articleClick: 0}
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                this.getData();
            },
            getData() {
                let data = {type: 'HOME'};
                let that = this;
                initData(data)
                    .then((res) => {
                        that.topArticleList = res.topArticleList;
                        that.newArticleList = res.newArticleList;
                        that.recommendArticle = res.recommendArticle;
                        that.hotArticle = res.hotArticle;
                        that.hotTag = res.hotTag;
                        that.hotFriend = res.hotFriend;
                        that.articleStatistics = res.articleStatistics;
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
        },
        components: {
            TopArticleList,
            NewArticleList,
            About,
            Search,
            HotArticle,
            HotTag,
            HotFriend,
            Active
        },
    };
</script>
<style scoped>

</style>
