import Vue from 'vue'
import App from './App.vue'
import router from 'router'
import store from 'store'
import 'common/elementui'
import {Message} from 'element-ui'
import global from  'common/global'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();
Vue.prototype.$GLOBAL = global;
Vue.prototype.$message = Message;
Vue.use(mavonEditor)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
