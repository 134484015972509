<template>
    <div id="article-list-component">
        <div class="article-list" v-if="list.length > 0">
            <div class="article-box" v-for="articleItem in list" :key="'articleIndex' + articleItem.code">
                <div class="article-box-left">
                    <div class="article-head">
                        <div class="day">{{articleItem.day}}</div>
                        <div class="year">{{articleItem.year}}/{{articleItem.month}}</div>
                    </div>
                    <div class="article-body">
                        <div class="top">
                            <div class="category" @click="goCategory(articleItem.categoryCode)">
                                {{articleItem.categoryName}}
                            </div>
                            <div class="title" @click="goArticle(articleItem.code)">{{articleItem.title}}</div>
                        </div>
                        <div class="bottom">
                            <div class="author">作者：Yancy</div>
                            <div class="date">{{articleItem.date}}</div>
                        </div>
                    </div>
                </div>
                <div class="article-box-right">
                    <div class="label">
                        <div class="label-item" v-if="articleItem.isTop * 1 === 1">
                            <div class="top-label">置顶</div>
                        </div>
                        <div class="label-item" v-if="articleItem.isRecommend * 1 === 1">
                            <div class="recommend-label">推荐</div>
                        </div>
                    </div>
                    <div class="number">
                        <div class="view">浏览：{{articleItem.click}}</div>
                        <div class="thumbs-up">点赞：{{articleItem.thumbsUp}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="article-no">
            <div class="value">没有数据</div>
        </div>
    </div>
</template>

<script>
    export default {    // 主内容-文章列表
        name: "article-list-component",
        data() {
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        created: function () {
        },
        methods: {
            goCategory(code) {
                this.$router.push({ path: "/category", query: { code } });
            },
            goArticle(code) {
                let url = '/article?code=' + code;
                window.open(url);
            }
        },
    }
</script>

<style scoped>
    #article-list-component {

    }

    /*文章列表*/
    #article-list-component .article-list {
        display: flex;
        flex-direction: column;
        background: #fff;
    }

    /*文章*/
    #article-list-component .article-box {
        padding: 15px;
        height: 60px;
        border-bottom: 1px dotted #f0f0f0;
        word-wrap: break-word;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #article-list-component .article-box:hover {
        background: -webkit-linear-gradient(left, #FFF, #F0F2F5, #FFF);
    }

    #article-list-component .article-box .article-box-left {
        display: flex;
        flex-direction: row;
    }

    #article-list-component .article-head {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        background: #f2f2f2;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #article-list-component .article-head .day {
        line-height: 20px;
        font-size: 20px;
        color: #666;
        font-weight: normal;
    }

    #article-list-component .article-head .year {
        font-size: 12px;
        color: #666;
        margin-top: 5px;
        line-height: 12px;
    }

    #article-list-component .article-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #article-list-component .article-body .top {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #article-list-component .article-body .top .category {
        height: 16px;
        line-height: 16px;
        padding: 0 5px;
        font-size: 12px;
        border: 1px solid #5FB878;
        background: 0 0;
        color: #5FB878;
        border-radius: 2px;
        margin-right: 10px;
    }

    #article-list-component .article-box:hover .article-body .top .title {
        color: #409EFF;
    }

    #article-list-component .article-body .top .title {
        font-size: 16px;
        /*font-weight: 500;*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
    }

    #article-list-component .article-body .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 22px;
        font-size: 14px;
        color: #999999;
    }

    #article-list-component .article-body .bottom .author {
        margin-right: 15px;
    }

    #article-list-component .article-box-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    #article-list-component .article-box-right .label {
        display: flex;
        flex-direction: row;
    }

    #article-list-component .article-box-right .label .label-item {
        height: 20px;
        margin-left: 10px;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        border: none;
        text-align: center;
    }

    #article-list-component .article-box-right .label .top-label {
        padding: 2px 6px;
        background: #393D49;
    }

    #article-list-component .article-box-right .label .recommend-label {
        padding: 2px 6px;
        background: #5FB878;
    }

    #article-list-component .article-box-right .label .hot-label {
        padding: 2px 6px;
        background: #FF6666;
    }

    #article-list-component .article-box-right .number {
        display: flex;
        flex-direction: row;
    }

    #article-list-component .article-box-right .number .view {
        line-height: 22px;
        font-size: 12px;
        color: #999;
        margin-right: 10px;
    }

    #article-list-component .article-box-right .number .thumbs-up {
        line-height: 22px;
        font-size: 12px;
        color: #999;
    }

    #article-list-component .article-no {
        background: #fff;
    }

    #article-list-component .article-no .value {
        height: 75px;
        line-height: 75px;
        text-align: center;
        font-size: 14px;
        color: #999;
    }

</style>