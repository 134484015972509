<template>
    <div class="tag-article-app" v-loading="loading">
        <div v-if="showPage" class="page-main-body">
            <div class="page-main-body-card">
                <friend-list v-bind:list="friend.list" v-bind:total="friend.total"></friend-list>
                <apply-friend></apply-friend>
            </div>
            <div class="page-main-right-card">
                <about v-bind:data="articleStatistics"></about>
                <active></active>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData} from "api/base";

    import ApplyFriend from "components/main/ApplyFriend";
    import FriendList from "components/main/FriendList";
    import HotArticle from "components/aside/HotArticle";
    import About from "components/aside/About";
    import Active from "components/aside/Active";

    export default {
        name: "tag-article-app",
        data() {
            return {
                showPage: false,
                loading: false,
                tag: {},
                friend : {total: 0, list: []},
                hotArticle: {total: 0, list: []}, // 热门文章数据
                articleStatistics: {articleTotal: 0, articleClick: 0},  // 文章统计
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                this.getData();
            },
            getData() {
                let that = this;
                let data = {type: 'FRIEND'};
                initData(data)
                    .then((res) => {
                        that.friend = res.friendList;   // 友情链接
                        that.hotArticle = res.hotArticle;   // 热门文章
                        that.articleStatistics = res.articleStatistics; // 文章统计
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },

        },
        components: {
            ApplyFriend,
            FriendList,
            About,
            HotArticle,
            Active
        },
    };
</script>
<style scoped>
    .tagBox{
        width: 100%;
        height: 100px;
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(18,18,18,.1);
        -moz-box-shadow: 0 1px 3px 0 rgba(18,18,18,.1);
        -webkit-box-shadow: 0 1px 3px 0 rgba(18,18,18,.1);
    }

    .tagBox .tagBody{
        width: 1170px;
        margin: 0 auto;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .tagBox .tagBody .tagIcon{

    }

    .tagBox .tagBody .tagName{
        margin-left: 12px;
        font-size: 25px;
        font-weight: 600;
    }
</style>
