import axios from 'axios'
import {BASE_URL} from 'common/config'
import Message from 'element-ui/lib'

export function ajax(options) {
    const instance = axios.create({
        baseURL: BASE_URL,
        timeout: 5000
    });
    // 请求拦截器
    instance.interceptors.request.use((config) => {
        return config
    }, (error) => Promise.reject(error));
    // 响应拦截器
    instance.interceptors.response.use((res) => {
        if (res.data.code * 1 !== 1000) {
            return Promise.reject(res.data.msg);
        }
        return res.data.data
    }, (error) => {
        Promise.reject(error)
    });

    return instance(options)
}