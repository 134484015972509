<template>
    <div id="hot-tag-component">
        <div class="title">热门标签</div>
        <div class="tag-box" v-if="total > 0">
            <div class="tag-item" v-for='tagItem in list' @click="goTag(tagItem.code)">
                {{tagItem.name}}
            </div>
        </div>
        <div class="more" v-if="total > 0" @click="goToMore">
            查看更多
        </div>
        <div class="null-tag" v-else>
            暂无数据
        </div>
    </div>
</template>

<script>
    export default {    // 侧边-热门标签
        name: "hot-tag-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            }
        },
        methods: {
            goTag(code) {
                this.$router.push({ path: "/tagArticle", query: { code } });
                this.$emit("tagCodeChange", code);
            },
            goToMore() {
                this.$router.push("/tag");
            },
        },
    }
</script>

<style scoped>
    #hot-tag-component {
        display: flex;
        flex-direction: column;
        width: 350px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #hot-tag-component .title {
        padding-left: 15px;
        font-weight: 500;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    #hot-tag-component .tag-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 15px 10px 15px;
    }

    #hot-tag-component .tag-item {
        width: 98px;
        height: 30px;
        line-height: 28px;
        font-size: 12px;
        color: #5E6D81;
        /*padding: 0 12px;*/
        margin: 0 10px 10px 0;
        border: 1px solid #DBE1E8;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -o-border-radius: 2px;
        border-radius: 2px;
        -webkit-box-sizing: 0 10px 10px hsla(0, 0%, 93.3%, .93);
        -moz-box-sizing: 0 10px 10px hsla(0, 0%, 93.3%, .93);
        -o-box-sizing: 0 10px 10px hsla(0, 0%, 93.3%, .93);
        box-sizing: 0 2px 15px hsla(0, 0%, 93.3%, .93);
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    #hot-tag-component .tag-item:nth-child(3n){
        margin-right: 0;
    }

    #hot-tag-component .tag-item:hover {
        /*-webkit-box-shadow: 0 10px 10px hsla(0,0%,93.3%,.93);*/
        /*box-shadow: 0 10px 10px hsla(0,0%,93.3%,.93);*/
        /*box-shadow: 0 2px 15px 0 rgb(153,153,153,20%);*/
        background: #409EFF;
        border: 1px solid #409EFF;
        color: #fff;
    }

    #hot-tag-component .more {
        border-top: 1px dotted #E9E9E9;
        color: #409EFF;
        font-size: 14px;
        line-height: 34px;
        text-align: center;
    }

    #hot-tag-component .more:hover {
        background: #409EFF;
        color: #fff;
    }

    #hot-tag-component .null-tag {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        line-height: 50px;
    }
</style>