<template>
    <div class="category" v-loading="loading">
        <div v-if="showPage" class="page-main-head">
            <div class="categoryBox" v-if="categoryList.total > 0">
                <div class="categoryList">
                    <div class="categoryItem" v-for="categoryItem in categoryList.list"
                         :key="'categoryIndex' + categoryItem.code"
                         :class="categoryItem.code == categoryCode ? 'categoryActive' : ''"
                         @click="categoryChange(categoryItem.code)">{{categoryItem.name}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPage" class="page-main-body">
            <div class="page-main-body-card">
                <new-article-list v-bind:list="articleList.list" v-bind:total="articleList.total"
                                  v-bind:page="page" v-on:pagechange="pageChange"></new-article-list>
            </div>
            <div class="page-main-right-card">
                <about v-bind:data="articleStatistics"></about>
                <active></active>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData} from "api/base";

    import NewArticleList from "components/main/NewArticleList";
    import HotArticle from "components/aside/HotArticle";
    import About from "components/aside/About";
    import Active from "components/aside/Active";

    export default {
        name: "category",
        data() {
            return {
                showPage: false,
                loading: false,
                categoryCode: '',
                page: 1,
                categoryList: {total: 0, list: []},
                articleList: {total: 0, list: []},
                hotArticle: {total: 0, list: []},
                articleStatistics: {articleTotal: 0, articleClick: 0}
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                let code = this.$route.query.code;
                if (!code) {
                    code = 'all';
                }
                this.page = 1;
                this.categoryCode = code;
                this.getData();
            },
            getData() {
                let that = this;
                let params = {
                    category: that.categoryCode
                };
                let data = {type: 'CATEGORY', params};
                initData(data)
                    .then((res) => {
                        that.categoryList = res.categoryList;
                        that.articleList = res.categoryArticleList;
                        that.hotArticle = res.hotArticle;
                        that.articleStatistics = res.articleStatistics;
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            getArticleList() {
                let that = this;
                let params = {
                    category: that.categoryCode,
                    page: that.page,
                };
                let data = {type: 'categoryArticleList', params};
                oneData(data)
                    .then((res) => {
                        that.articleList = res;
                    })
                    .catch((err) => {
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            categoryChange(res) {
                let that = this;
                let newUrl = '/category?code=' + res;
                history.pushState({}, null, newUrl);
                that.categoryCode = res;
                that.page = 1;
                that.getArticleList();
                return false;
            },
            pageChange(res) {
                let that = this;
                that.page = res;
            }
        },
        components: {
            NewArticleList,
            About,
            HotArticle,
            Active
        },
    };
</script>
<style scoped>
    .categoryBox {
        width: 100%;
        height: 50px;
    }

    .categoryBox .categoryList {
        width: 1170px;
        margin: 20px auto 0;
        height: 50px;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 4px 30px rgba(0, 0, 0, .08);
        -moz-box-shadow: 0 4px 30px rgba(0, 0, 0, .08);
        -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, .08);
    }

    .categoryBox .categoryItem {
        padding: 0;
        margin: 0 30px;
        height: 50px;
        line-height: 50px;
        color: #454545;
        font-size: 16px;
    }

    .categoryBox .categoryItem:hover {
        /*font-weight: 600;*/
        color: #409EFF;
    }

    .categoryBox .categoryItem.categoryActive {
        /*font-weight: 600;*/
        color: #409EFF;
    }
</style>
