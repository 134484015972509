<template>
    <div class="search-app" v-loading="loading">
        <div v-if="showPage" class="page-main-head"></div>
        <div v-if="showPage" class="page-main-body">
            <div class="page-main-body-card">
                <search-article-list v-bind:list="articleSearch.list" v-bind:total="articleSearch.total"
                                     v-bind:keyword="keyWord" ></search-article-list>
            </div>
            <div class="page-main-right-card">
                <search flag="out" v-on:onsearch="onSearch"></search>
                <active></active>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData} from "api/base";

    import SearchArticleList from "components/main/SearchArticleList";
    import HotArticle from "components/aside/HotArticle";
    import About from "components/aside/About";
    import Search from "components/aside/Search";
    import Active from "components/aside/Active";

    export default {
        name: "search-app",
        data() {
            return {
                showPage: false,
                loading: false,
                keyWord: '',
                articleSearch: {total: 0, list: []},
                hotArticle: {total: 0, list: []},
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                let keyWord = this.$route.query.keyWord;
                this.page = 1;
                this.keyWord = keyWord;
                this.getData();
            },
            getData() {
                let that = this;
                let params = {
                    keyWord: that.keyWord
                };
                let data = {type: 'SEARCH', params};
                initData(data)
                    .then((res) => {
                        that.articleSearch = res.articleSearch;
                        that.hotArticle = res.hotArticle;
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            getArticleList() {
                let that = this;
                let params = {
                    keyWord: that.keyWord
                };
                let data = {type: 'articleSearch', params};
                oneData(data)
                    .then((res) => {
                        that.articleSearch = res;
                    })
                    .catch((err) => {
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            onSearch: function(keyWord){
                this.keyWord = keyWord;
                let newUrl = '/search?keyWord=' + keyWord;
                history.pushState({},null,newUrl);
                this.getArticleList();
            },
            pageChange(res) {
                let that = this;
                that.page = res;
            }
        },
        components: {
            SearchArticleList,
            HotArticle,
            Search,
            About,
            Active
        },
    };
</script>
<style scoped>

</style>
