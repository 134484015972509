import {
  ajax,
} from './index'

// 初始化信息
export function initData(data) {
  return ajax({
    // url: '/proxy/api/initData',
    url: '/api/initData',
    method: 'POST',
    params: {
    },
    data
  })
}

// 请求单个数据
export function oneData(data) {
    return ajax({
        // url: '/proxy/api/oneData',
        url: '/api/oneData',
        method: 'POST',
        params: {
        },
        data
    })
}

// 点击文章
export function clickArticle(data) {
    return ajax({
        // url: '/proxy/api/clickArticle',
        url: '/api/clickArticle',
        method: 'POST',
        params: {
        },
        data
    })
}

