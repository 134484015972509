<template>
    <div id="new-article-list-component">
        <div class="new-article-head">
            最新
        </div>
        <div class="new-article-list">
            <article-list v-bind:list="list"></article-list>
        </div>
        <div class="new-article-pagination" v-if="total > 10">
            <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="total"
                    prev-text="上页"
                    next-text="下页"
                    @current-change="pageChange"
                    :current-page="page"
                    hide-on-single-page="true"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import ArticleList from "components/main/ArticleList";

    export default {    // 主内容- 最新文章列表
        name: "new-article-list-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            },
            page: {
                type: Number,
                default() {
                    return 0;
                },
            }
        },
        methods: {
            pageChange(page) {
                this.currentPage = page;
                this.$emit("pagechange", page);
            }
        },
        watch: {},
        components: {
            ArticleList
        },
    }
</script>

<style scoped>

    #new-article-list-component {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #new-article-list-component .new-article-head {
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
    }

    #new-article-list-component .new-article-pagination {
        height: 50px;
        background: #fff;
        text-align: center;
    }

    #new-article-list-component .el-pagination {
        padding-top: 15px;
    }
</style>