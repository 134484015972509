<template>
    <div id="link-list-component" class="showShadow">
        <div class="link-box" v-if="total > 0">
            <div class="link-item" v-for="linkItem in list"
                 @click="goLink(linkItem.url)">
                <el-image style="width: 50px;" :src="linkItem.imgData" :fit="'fill'"></el-image>
                <div class="link-name">{{linkItem.name}}</div>
            </div>
        </div>
        <div class="link-null" v-else>
            暂无数据
        </div>
    </div>
</template>

<script>
    export default {    // 主内容-资源列表
        name: "link-list-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            }
        },
        methods: {
            goLink (url) {
                window.open(url);
            }
        },       //定义组件的方法
    }
</script>

<style scoped>
    #link-list-component {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #fff;
        margin-bottom:20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #link-list-component .link-box{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
        padding: 15px;
        margin-top: 20px;
    }

    #link-list-component .link-box .link-item{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 330px;
        padding: 15px;
        border: 1px solid #F2F2F2;
        margin-bottom: 20px;
        margin-right: 25px;
    }

    #link-list-component .link-box .link-item:nth-child(3n){
        margin-right: 0;
    }

    #link-list-component .link-box .link-item:hover{
        color: #409EFF;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        border: 1px solid #409EFF;
    }


    #link-list-component .link-box .link-item .link-name{
        margin-left: 15px;

        font-size: 14px;
    }


    #link-list-component .link-null{
        text-align: center;
        font-size: 14px;
        color: #ccc;
        height: 152px;
        line-height: 152px;
    }

</style>