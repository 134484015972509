<template>
    <div class="tag-app" v-loading="loading">
        <div v-if="showPage" class="page-main-head">
        </div>
        <div v-if="showPage" class="page-main-body">
            <div class="page-main-body-card">
                <div class="tag-cloud">
                    <div class="cloud-title">
                        标签云
                    </div>
                    <div class="cloud-body" v-if="tagList.total > 0">
                        <div class="cloud-item" v-for="tagItem in tagList.list" :key="'tagIndex' + tagItem.code"
                             @click="goTag(tagItem.code)">{{tagItem.name}}</div>
                    </div>
                    <div class="tag-no" v-else>
                        <div class="value">没有数据</div>
                    </div>
                </div>

            </div>
            <div class="page-main-right-card">
                <about v-bind:data="articleStatistics"></about>
                <active></active>
                <hot-article v-bind:list="hotArticle.list" v-bind:total="hotArticle.total"></hot-article>
            </div>
        </div>
        <div v-if="!showPage" id="page-main-loading">&nbsp;</div>
    </div>
</template>

<script>
    import {initData, oneData} from "api/base";

    import HotArticle from "components/aside/HotArticle";
    import About from "components/aside/About";
    import Active from "components/aside/Active";

    export default {
        name: "tag-app",
        data() {
            return {
                showPage: false,
                loading: false,
                tagList:{total: 0, list: []}, // 标签列表
                hotArticle: {total: 0, list: []}, // 热门文章数据
                articleStatistics: {articleTotal: 0, articleClick: 0},  // 文章统计
            };
        },
        created() {
            this.initPage();
        },
        mounted() {
        },
        methods: {
            initPage(){
                this.loading = true;
                this.getData();
            },
            getData() {
                let that = this;
                let params = {

                };
                let data = {type: 'TAG', params};
                initData(data)
                    .then((res) => {
                        that.tagList = res.tagList;  // 标签列表
                        that.hotArticle = res.hotArticle;  // 热门文章
                        that.articleStatistics = res.articleStatistics;  // 文章统计
                        if(that.loading){
                            that.loading = false;
                        }
                        that.showPage = true;  // 显示页面
                    })
                    .catch((err) => {
                        if(that.loading){
                            that.loading = false;
                        }
                        this.$message({showClose: true, message: err, type: 'error'});
                    });
            },
            // 打开文章详情
            goTag: function (code) {
                this.$router.push({ path: "/tagArticle", query: { code } });
            }
        },
        components: {
            About,
            HotArticle,
            Active
        },
    };
</script>
<style scoped>
    .tag-cloud{
        display: flex;
        flex-direction:column;
        width: 100%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        background: #fff;
    }
    .tag-cloud .cloud-title{
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
    }
    .tag-cloud .cloud-body{
        display: flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content: flex-start;
        padding: 15px;
        margin-bottom: 15px;
    }
    .tag-cloud .cloud-body .cloud-item{
        width: 178px;
        border: 1px solid #ccc;
        margin: 15px 15px 0 0;
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: #333;
        font-size: 14px;
    }

    .tag-cloud .cloud-body .cloud-item:hover{
        color: #fff;
        background: #409EFF;
    }
    .tag-cloud .tag-no{
        height: 75px;
        text-align: center;
    }
    .tag-cloud .tag-no .value{
        color: #666;
        line-height: 75px;
        font-size: 14px;
    }
</style>
