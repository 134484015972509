<template>
    <div id="friend-list-component" class="showShadow">
        <div class="friend-list-head">
            友情链接
        </div>
        <div class="friend-list-list">
            <div class="friend-box" v-if="total > 0">
                <div class="friend-item" v-for="friendItem in list"
                     @click="goLink(friendItem.url)">
                    <div class="friend-name">{{friendItem.name}}</div>
                    <div class="friend-description">{{friendItem.name}}</div>
                </div>
            </div>
            <div class="friend-null" v-else>
                暂无数据
            </div>
        </div>
    </div>
</template>

<script>
    export default {    // 主内容-资源列表
        name: "friend-list-component",
        data() {
            //定义组件的数据
            return {}
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            total: {
                type: Number,
                default() {
                    return 0;
                },
            }
        },
        methods: {
            goLink (url) {
                window.open(url);
            }
        },       //定义组件的方法
    }
</script>

<style scoped>
    #friend-list-component {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #fff;
        margin-bottom:20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #friend-list-component .friend-list-head {
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
    }

    #friend-list-component .friend-box{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
        padding: 30px 15px 15px 15px;
    }

    #friend-list-component .friend-box .friend-item{
        display: flex;
        flex-direction: column;
        width: 208px;
        padding: 20px;
        border: 1px solid #F2F2F2;
        margin-bottom: 15px;
        margin-right: 15px;
    }

    #friend-list-component .friend-box .friend-item:nth-child(3n){
        margin-right: 0;
    }

    #friend-list-component .friend-box .friend-item:hover{

        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        /*border: 1px solid #409EFF;*/
    }


    #friend-list-component .friend-box .friend-item .friend-name{
        line-height: 28px;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
    }

    #friend-list-component .friend-box .friend-item .friend-name:hover{
        color: #409EFF;
    }

    #friend-list-component .friend-box .friend-item .friend-description{
        margin-top: 15px;
        font-size: 14px;
        text-align: left;
        line-height: 25px;
        height: 50px;
        color: #666;
    }

    #friend-list-component .friend-null{
        text-align: center;
        font-size: 14px;
        color: #ccc;
        height: 152px;
        line-height: 152px;
    }

</style>