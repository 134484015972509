<template>
    <div id="search-component" class="showShadow">
        <div class="search-box">
            <el-input placeholder="请输入搜索内容" v-model="keyWord" class="input-search" clearable>
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
        </div>
    </div>
</template>

<script>
    export default {    // 侧边-搜索
        name: "about-component",
        data() {
            //定义组件的数据
            return {
                keyWord: ''
            }
        },
        props: {
            flag: {
                type: String,
                default() {
                    return 'in';
                },
            },
        },
        methods: {
            // 跳转到标签页
            search () {
                if(!this.keyWord){
                    this.$message({showClose: true, message: '搜索内容不能为空', type: 'error'});
                    return false;
                }
                if(this.flag == 'out'){
                    this.$emit("onsearch", this.keyWord);
                }else{
                    let url = '/search?keyWord=' + this.keyWord;
                    window.open(url);
                }
            },
        },       //定义组件的方法
    }
</script>

<style scoped>

    #search-component{
        display: flex;
        flex-direction: column;
        width: 350px;
        margin-bottom:20px;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
    }

    #search-component .search-box{
        padding: 20px 15px;
    }



</style>