<template>
    <div id="apply-friend-component" class="showShadow">
        <div class="apply-friend-head">
            友情申请
        </div>
        <div class="apply-friend-list">
            <div class="applyBox">
                <div class="siteInfo">
                    <p>名称：小莫陋室</p>
                    <p>地址：http://blog.liaoyansi.cn</p>
                    <p>介绍：小莫的技术自留地。</p>
                    <p>邮箱：leoyancy@163.com</p>
                    <p>需要换友链的朋友，先挂上本站友链，然后发送【网站名称+网站地址+网站描述+邮箱地址】到邮箱，审核通过后会挂载到本站。</p>
                </div>
                <div class="tips">
                    <p><b>温馨提示：</b></p>
                    <p>在申请本站友链之前请确保已将本站加入友链。</p>
                    <p>本站优先录入同类原创、技术类博客。任何包含违法国家法律或不健康内容站点均不录入。</p>
                    <p>本站会不定期互相走访友链，发现单方取消友链便拉入黑名单。</p>
                    <p>特别提醒: 若无意外，在申请友链后24小时内完成审核并录入站点，如超时还未审核完成，请Email或者微信我。审核结果均以邮件形式通知，请输入常用有效邮箱。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {    // 侧边-搜索
        name: "apply-friend-component",
        data() {
            //定义组件的数据
            return {
            }
        },
        methods: {
        },       //定义组件的方法
    }
</script>

<style scoped>

    #apply-friend-component{

    }
    #apply-friend-component .apply-friend-head {
        background: #fff;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border-bottom: 1px dotted #E9E9E9;
        color: #409EFF;
    }

    #apply-friend-component .apply-friend-list{
        background: #fff;
        padding: 15px;
    }

    #apply-friend-component .applyBox{
        margin: 0;
    }
    #apply-friend-component .applyBox .siteInfo{
        padding: 10px;
        background: #f9f9f9;
        color: #999;
        font-size: 14px;
    }

    #apply-friend-component .applyBox .tips{
        color: #96969c;
        font-size: 14px;
    }


</style>